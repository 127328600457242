<template>
    <div v-if="isLoading">
        Loading 
        <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden"></span>
        </div>
    </div>
    <ODataLookup v-else 
                    :data-object="dsConfiguredLkp" 
                    class="form-select form-select-sm" 
                    :value="config.Value" 
                    :multiselect="config.Multiselect" 
                    :bind="sel => { $emit('callbackFunction', sel) }">
        <template v-for="column in props.config.Fields" :key="column.name">
            <OColumn :colId="column.name" :width="column.width ? column.width : column.name.includes('ID') ? 100 : 250" :headerName="column.name" filter="OFilter"></OColumn>
        </template>
    </ODataLookup>
</template>

<script setup>
    import { ref, onMounted } from "vue";
    import { getOrCreateDataObject } from "o365-dataobject";

    // config prop supported values
    // Value:string
    // ViewName:string
    // Fields:[{name:string, type:string, width:number }]
    // Multiselect:false
    // DisplayField:string - to be implemented
    const props = defineProps({
        config: {
            Type: Object,
            Default: null,
            Required: false
        }
    });

    const isLoading = ref(true)
    var dsConfiguredLkp = {};
    
    function createLookupDataSource(){
        if(!props.config.ViewName){
            return;
        }
        dsConfiguredLkp = getOrCreateDataObject({
            id: 'dsConfiguredLkp' + crypto.randomUUID(),
            viewName: props.config.ViewName,
            maxRecords: 100,
            whereClause: "",
            selectFirstRowOnLoad: true,
            loadRecents: false,
            distinctRows: true,
            fields: props.config.Fields ? props.config.Fields : props.config.Columns
        });
        dsConfiguredLkp.load().then(()=>{
            isLoading.value = false;
        });
    }

    onMounted(()=>{
        createLookupDataSource();
    });
</script>